import React from "react";

const SectionSubtitle = ({ children, color = "dark" }) => {
  return (
    <h3
      className={`text-sm font-semibold mb-3 uppercase subtitle-decorator ${
        color === "dark"
          ? "text-gray-500 subtitle-decorator--dark"
          : "text-gray-100 subtitle-decorator--light"
      }`}
    >
      {children}
    </h3>
  );
};

export default SectionSubtitle;
