import React from "react";

const TagsSection = ({ title, tags }) => {
  const half = Math.ceil(tags.length / 2);

  const firstHalf = tags.splice(0, half);
  const secondHalf = tags.splice(-half);
  return (
    <section className="bg-gray-100 px-5 py-10">
      <div className="flex flex-row flex-wrap md:flex-nowrap">
        <div className="w-full md:w-1/3">
          <h2 className="text-2xl font-semibold mb-3">{title}</h2>
        </div>
        <div className="w-full md:w-1/3">
          <ul className="list-disc list-inside">
            {firstHalf.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
        <div className="w-full md:w-1/3">
          <ul className="list-disc list-inside">
            {secondHalf.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default TagsSection;
