import React from "react";
import Layout from "../../components/layout/Layout";
import { useStaticQuery, graphql } from "gatsby";
import ImageSection from "../../components/ui/sections/ImageSection";
import TagsSection from "../../components/ui/sections/TagsSection";

const WdresiePage = () => {
  const query = useStaticQuery(graphql`
    query {
      articleImg: file(relativePath: { eq: "portfolio/wdresie.png" }) {
        childImageSharp {
          gatsbyImageData(width: 600)
        }
      }
    }
  `);

  const tags = [
    "Prestashop",
    "architektura informacji",
    "integracja z hurtowniami",
    "nowoczesny projekt graficzny",
    "intuicyjny interfejs użytkownika",
    "system zarządzania treścią",
  ];

  return (
    <Layout pageTitle="wdresie.pl">
      <main className="container mx-auto px-5">
        <ImageSection
          subtitle="Nowoczesny system sprzedażowy"
          title="E-commerce dla sport-fashion"
          image={query.articleImg.childImageSharp.gatsbyImageData}
        >
          <p className="text-gray-700 text-base mb-4">
            Wykonaliśmy w pełni funkcjonalną platformę e-Commerce w branży
            sport-fashion. Sklep wyróżnia się nowoczesnym designem, autorskim
            systemem integracji z hurtowniami oraz przemyślanym procesem
            zakupowym.
          </p>
          <p className="text-gray-700 text-base mb-4">
            Sklep wdrożyliśmy i opiekujemy się nim na stałe dodając kolejne
            usprawnienia. W myśl zasady "kto stoi w miejscu, ten się cofa",
            realizujemy coraz nowe wizje i wyzwania nowego gracza na rynku.
          </p>
        </ImageSection>
        <TagsSection title="Projekt w pigułce" tags={tags} />
      </main>
    </Layout>
  );
};

export default WdresiePage;
