import React from "react";

const SectionTitle = ({ children, color = "dark", align = "left" }) => {
  return (
    <h2
      className={`text-2xl md:text-3xl font-semibold mb-5 
      ${color === "dark" ? "text-gray-800" : "text-white"} 
      ${align === "left" ? "text-left" : "text-center"}`}
    >
      {children}
    </h2>
  );
};

export default SectionTitle;
