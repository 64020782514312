import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import SectionTitle from "../SectionTitle";
import SectionSubtitle from "../SectionSubtitle";

const ImageSection = ({ children, image, title, subtitle }) => {
  return (
    <section className="flex flex-wrap md:flex-nowrap mb-10 items-center">
      <div className="md:w-5/12">
        <GatsbyImage image={image} alt={title} />
      </div>
      <div className="md:w-7/12 bg-gray-100">
        <div className="p-12 md:p-24">
          <SectionSubtitle>{subtitle}</SectionSubtitle>
          <SectionTitle>{title}</SectionTitle>
          <div className="text-justify">{children}</div>
        </div>
      </div>
    </section>
  );
};

export default ImageSection;
